import axios from "../instance";

export default {
    getAll: (companyId, branchId) => { // return axios.api.get(`/customer?warehouseId=${warehouseId}`).then((response) => response.data);
        if (branchId) {
            return axios.api.get(`/routes?companyId=${companyId}&branchId=${branchId}`).then((response) => response.data);
        } else {
            return axios.api.get(`/routes?companyId=${companyId}`).then((response) => response.data);
        }
    },
    getOne: (id) => {
        return axios.api.get(`/routes/${id}`).then((response) => response.data);
    },
    search: (body) => {
        return axios.api.post("/routes/search", body).then((response) => response.data);
    },
    create: (body) => {
        return axios.api.post("/routes", body).then((response) => response.data);
    },
    update: (id, body) => {
        return axios.api.put(`/routes/${id}`, body).then((response) => response.data);
    },
    delete: (id) => {
        return axios.api.delete(`/routes/${id}`).then((response) => response.data);
    }
};
