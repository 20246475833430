import axios from "../instance";

export default {
  getAll: () => {
    return axios.api.get("/mProvinces").then((response) => response.data);
  },
  getOne: (id) => {
    return axios.api
      .get(`/mProvinces/${id}`)
      .then((response) => response.data);
  },
  search: (body) => {
    return axios.api
      .post("/mProvinces/search", body)
      .then((response) => response.data);
  },
  create: (body) => {
    return axios.api
      .post("/mProvinces", body)
      .then((response) => response.data);
  },
  update: (id, body) => {
    return axios.api
      .put(`/mProvinces/${id}`, body)
      .then((response) => response.data);
  },
  delete: (id) => {
    return axios.api
      .delete(`/mProvinces/${id}`)
      .then((response) => response.data);
  },
  getAllProvince: () => {
    return axios.api.get("https://thai-drug-service-temp.yuzudigital.com/locations/findAllProvinces").then((response) => response.data);
  },
  // getAllDistrict: (id) => {
  //   return axios.api.get(`https://thai-drug-service-temp.yuzudigital.com/locations/findDistrictByProvinceId/1${id}`).then((response) => response.data);
  // },
  getAllDistrict: (id) => {
    return axios.api.get(`https://thai-drug-service-temp.yuzudigital.com/locations/findDistrictByProvinceId/${id}`).then((response) => response.data);
  },
  getAllSubdistrict: (id) => {
    return axios.api.get(`https://thai-drug-service-temp.yuzudigital.com/locations/findSubDistrictByDistrictId/${id}`).then((response) => response.data);
  },
};
