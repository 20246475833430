import province from "./province";
import district from "./district";
import subdistrict from "./subdistrict";
import address from "./address";


export default {
    province,
    district,
    subdistrict,
    address
    
};
