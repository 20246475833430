import route from "./route";
import customer from "./customer";
import discount from "./discount";
import store_type from "./store_type";
import sector from "./sector";
import zone from "./zone";
import boxcode from "./boxcode";

export default {
    customer,
    discount,
    route,
    store_type,
    sector,
    zone,
    boxcode

};
