import axios from "../instance";

export default {
    getAll: (companyId) => {
        if (companyId) {
            return axios.api.get(`/promotions?companyId=${companyId}`).then((response) => response.data);
        } else {
            return axios.api.get("/promotions").then((response) => response.data);
        }
    },
    getOne: (id) => {
        return axios.api.get(`/promotions/${id}`).then((response) => response.data);
    },
    search: (body) => {
        return axios.api.post("/promotions/search", body).then((response) => response.data);
    },
    create: (body) => {
        return axios.api.post("/promotions", body).then((response) => response.data);
    },
    update: (id, body) => {
        return axios.api.put(`/promotions/${id}`, body).then((response) => response.data);
    },
    delete: (id) => {
        return axios.api.delete(`/promotions/${id}`).then((response) => response.data);
    },
    checkPromotion: (body) => {
        return axios.api.post("/promotions/checkPromotion", body).then((response) => response.data);
    },
};
