import axios from "../instance";

export default {
    getAll: (companyId) => {
        if (companyId) {
            return axios.api.get(`/districtDiscounts?companyId=${companyId}`).then((response) => response.data);
        } else {
            return axios.api.get("/districtDiscounts").then((response) => response.data);
        }
    },
    getOne: (id) => {
        return axios.api.get(`/districtDiscounts/${id}`).then((response) => response.data);
    },
    search: (body) => {
        return axios.api.post("/districtDiscounts/search", body).then((response) => response.data);
    },
    create: (body) => {
        return axios.api.post("/districtDiscounts", body).then((response) => response.data);
    },
    update: (id, body) => {
        return axios.api.put(`/districtDiscounts/${id}`, body).then((response) => response.data);
    },
    delete: (id) => {
        return axios.api.delete(`/districtDiscounts/${id}`).then((response) => response.data);
    }
};
