import axios from "../instance";

export default {
    getAll: (warehouseId) => { // return axios.api.get(`/customer?warehouseId=${warehouseId}`).then((response) => response.data);
        return axios.api.get(`/boxs`).then((response) => response.data);
    },
    getOne: (id) => {
        return axios.api.get(`/boxs/${id}`).then((response) => response.data);
    },
    search: (body) => {
        return axios.api.post("/boxs/search", body).then((response) => response.data);
    },
    create: (body) => {
        return axios.api.post("/boxs", body).then((response) => response.data);
    },
    update: (id, body) => {
        return axios.api.put(`/boxs/${id}`, body).then((response) => response.data);
    },
    delete: (id) => {
        return axios.api.delete(`/boxs/${id}`).then((response) => response.data);
    }
};
